import * as React from 'react';
import { injectFont } from './injectFont';
export default class GoogleFont extends React.PureComponent {
  constructor() {
    super(...arguments); // Uses Google's CDN to request the desired font

    this.componentDidMount = () => {
      const {
        primaryFont = '',
        secondaryFonts = []
      } = this.props;
      handleGoogleFonts([primaryFont, ...secondaryFonts]);
      injectFont(cleanFontName(primaryFont), secondaryFonts.map(font => cleanFontName(font)));
    };
  }

  render() {
    return null;
  }

}

function cleanFontName(family) {
  // Takes a font formatted as `Source+Sans+Pro:400,600&amp;subset=greek-ext`
  // and returns 'Source Sans Pro'
  return family.split('&')[0].split(':')[0].replace(/\+/gi, ' ');
}

function handleGoogleFonts(families) {
  // import external font stylesheet and store it in session storage to optimise
  // future loads
  // Check for document first as this should not run on the server
  document && import('store-css').then(module => families.map(family => module.default.css(`https://fonts.googleapis.com/css?family=${family}`, {
    crossOrigin: 'anonymous',
    storage: 'session'
  })));
}